import React from 'react'

import Layout from '../../../components/layout'
import { ApplicationList } from '../../../components/job/application-list'

const ApplicationReferencePage = ({ params: { job } }) => {
  return (
    <Layout tocEnabled={false}>
      <ApplicationList job={job} />
    </Layout>
  )
}

export default ApplicationReferencePage
